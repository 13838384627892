import React from "react";
import LandingPage from "./components/LandingPage";
import About from "./components/About";
import HeroBanner from './components/Hero';
import BlogPage from './components/Blogs'; 

import { 
  Container,
  Typography,
  Link, 
  Stack, 
  Box, 
} from '@mui/material';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {  
  return (
    <div>
      <HeroBanner/>
      <br/>
      <Router>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/about" element={<About />} />
            <Route path='/blogs' element={<BlogPage />} />
          </Routes>
      </Router>
      <br/>
      <Box 
        component="footer"
        sx={{
          py: 3,                     // Padding on top and bottom
          px: 2,                     // Padding on left and right
          mt: 'auto',                // Pushes the footer to the bottom
          backgroundColor: "#393b49", 
          color: "white"
        }}>
        <Container maxWidth="lg">
          <Typography variant="body1">
            HeroParty {new Date().getFullYear()} 
          </Typography>
          <Stack direction="row" spacing={2}>
            <Link color="inherit" href="/about">Our Story</Link>
            <Link color="inherit" href="/blogs">Blogs</Link>
            <Link color="inherit" href="mailto:ceo.heroparty@gmail.com">Contact Us</Link>
          </Stack>
        </Container>
      </Box>
    </div>
    
  );
}

export default App;