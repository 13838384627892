import React, { useState } from 'react';

import { supabase } from './supabaseClient';

import { Box, Typography, Button, TextField, Link } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';

import milkyWayImage from '../assets/stellar.jpeg';

function HeroBanner() {
    const [email, setEmail] = useState('');
    const [error, setError] = useState(false);
  
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (! error) {
            const { error } = await supabase
            .from('interest')
            .insert([
                { 'email': email }, 
            ]);

            if (error) {
                console.error('Error inserting data:', error);
            } else {
                setEmail('');
            }
        } 
    };

    // Regular expression for validating an email
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    // Handle email change
    const handleEmailChange = (e) => {
        const value = e.target.value;
        setEmail(value);

        // Check if email matches regex pattern
        if (emailRegex.test(value)) {
            setError(false); // Set error to false if email is valid
        } else {
            setError(true); // Set error to true if email is invalid
        }
    };

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: '400px', // Adjust height as needed
        backgroundImage: `url(${milkyWayImage})`, 
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        textAlign: 'center',
      }}
    >
      {/* Overlay Text */}
      <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0, 0, 0, 0.3)', zIndex: 1 }}></Box>
      {/* Content */}
      <Box sx={{ position: 'flex', zIndex: 2 }}>
        <Typography sx={{ fontWeight: 'bold', fontSize: '11vw'}}>
          <Link color="inherit" href="/" underline="none">HeroParty</Link>
        </Typography>
        <Typography sx={{ fontSize: '2.5vw', mb: 2 }}>
            Unlock the power of your data with HeroParty
        </Typography>
        <Box
          sx={{
            display: 'flex',    // Flexbox layout
            alignItems: 'center', // Align items vertically
            gap: 1,              // Spacing between TextField and Button
          }}
        >
          <TextField 
            label="Enter your email..."  
            fullWidth
            value={email}
            onChange={handleEmailChange}
            error={error}
            sx={{
              backgroundColor: 'transparent',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'rgba(255, 255, 255, 0.5)', 
                },
                '&:hover fieldset': {
                  borderColor: 'white', 
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'white', 
                },
              },
              input: {
                color: 'white',
              },
              label: {
                color: 'white'
              }
            }}
          />
          <Button 
            onClick={handleSubmit} 
            variant="outlined"
            endIcon={<SendIcon />}
            sx={{
              width: "30vw", 
              borderColor: 'white',
              color: 'white',
              fontWeight: 'bold', 
              fontSize: '2vw', 
            }}
            >
              Join Early Access
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default HeroBanner;
