// src/components/About.js
import React from 'react';

import { Typography, Container, Card, Stack, CardContent } from '@mui/material';

const About = () => {
    return (
        <Container>
            <Typography sx={{ fontSize: '6vw', textAlign: 'center' }} gutterBottom>
                About Us
            </Typography>
            <Stack spacing={2}>
                <Card>
                    <CardContent>
                        <Typography sx={{ fontSize: '4vw', textAlign: 'center' }}>
                            What is data but a point in a story. <b>Own Your Story</b>.
                        </Typography>
                    </CardContent>
                </Card>
                <Card>
                    <CardContent>
                        <Typography sx={{ fontSize: '5vw', textAlign: 'center' }}>Why you need a hero</Typography>
                        <Typography sx={{ fontSize: '3vw', textAlign: 'center' }}>
                            We want to make your life easier by unlocking the data that companies hold on you by utilising GDPR's Subject Access Request.  
                        </Typography>
                    </CardContent>
                </Card>
                <Card>
                    <CardContent>
                        <Typography sx={{ fontSize: '5vw', textAlign: 'center' }}>Who are we? </Typography>
                        <Typography sx={{ fontSize: '3vw', textAlign: 'center' }}>
                            me me me 
                        </Typography>
                    </CardContent>
                </Card>
             </Stack>
        </Container>
    );
};

export default About;
