import React from 'react';

import { 
  Container,
  Typography,
  Card, 
  CardContent, 
  CardMedia, 
  Box, 
  Stack, 
  Divider, 
} from '@mui/material';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import MobileFriendlyIcon from '@mui/icons-material/MobileFriendly';

import coupleImage from '../assets/couple.png';
import passportImage from '../assets/passport.png';
import headphonesImage from '../assets/headphones.png';


const LandingPage = () => {  
  return (
    <Container>
      <Stack direction="row" spacing={1}>
        <Card sx={{ backgroundColor: '#393b49', color: '#c0f7fc'}} variant="outlined">
          <CardContent>
            <Typography sx={{ fontSize: '5vw' }}>Collect</Typography>
            <Typography sx={{ fontSize: '2vw' }}>
              We identify and collect <b>your data</b> from companies using a range of <u>Zero Data</u> methods like <u>SARs</u>
            </Typography>
          </CardContent>
        </Card>
        <Card sx={{ backgroundColor: '#393b49', color: '#c0f7fc'}}  variant="outlined">
          <CardContent>
          <Typography sx={{ fontSize: '5vw' }}>Store</Typography>
            <Typography sx={{ fontSize: '2vw' }}>
              Your data is then securely stored and anything identifying you is encrypted
            </Typography>
          </CardContent>
        </Card>
        <Card sx={{ backgroundColor: '#393b49', color: '#c0f7fc'}}  variant="outlined">
          <CardContent>
            <Typography sx={{ fontSize: '5vw' }}>Value</Typography>
            <Typography sx={{ fontSize: '2vw' }}>
              We then use our combined 20 years of experience to provide industry professional standard analytics to our users
            </Typography>
          </CardContent>
        </Card>
      </Stack>
      <br/>
      <Card sx={{ display: 'flex', backgroundColor: '#338395', color: 'white'}}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <CardContent sx={{ flex: '1 0 auto' }}>
            <Typography sx={{ fontSize: '5vw' }}>
              Your Data Working For You
            </Typography>
            <Typography sx={{ fontSize: '2vw' }}>
              We unlock the power of your data to benefit you. Using the data you're entitled to, we perform world-class analytics to help you save money and maximize your time.
              <Divider sx={{ my: 1 }}/>
              From everyday groceries to special celebrations, we provide personalised insights and recommendations to enhance what matters most to you.
            </Typography>
          </CardContent>
        </Box>
        <CardMedia
          component="img"
          sx={{ width: "30vw" }}
          image={coupleImage}
          alt="Live from space album cover"
        />
      </Card>
      <br/>
      <Card sx={{ display: 'flex', backgroundColor: '#dd9e85', color: '#393b49'}}>
        <CardMedia
          component="img"
          sx={{ width: "30vw" }}
          image={passportImage}
          alt="Live from space album cover"
        />
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <CardContent sx={{ flex: '1 0 auto' }}>
            <Typography sx={{ fontSize: '5vw' }}>
              Verification
            </Typography>
            <Typography sx={{ fontSize: '2vw' }}>
              We create your digital identity, giving you control over your online information and the ability to verify your identity with other companies.
              <Divider sx={{ my: 1 }}/>
              Easily update your details across services—no need to notify multiple organisations. You decide which companies or government services we share your information with.
            </Typography>
          </CardContent>
        </Box>
      </Card>        
      <br/>
      <Card sx={{ display: 'flex', backgroundColor: '#393b49', color: '#c0f7fc'}}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <CardContent sx={{ flex: '1 0 auto' }}>
            <Typography sx={{ fontSize: '5vw' }}>
              Portability
            </Typography>
            <Typography sx={{ fontSize: '2vw' }}>
              Switching services shouldn't mean starting over. At HeroParty, we eliminate barriers by seamlessly rebuilding your profiles, playlists, and accounts, making transitions smooth while preserving your hard work.
            </Typography>
          </CardContent>
        </Box>
        <CardMedia
          component="img"
          sx={{ width: "30vw" }}
          image={headphonesImage}
          alt="Live from space album cover"
        />
      </Card>        
      <br/>
      <Stack direction="row" spacing={1}>
        <Card variant="outlined" sx={{backgroundColor: '#338395', color: 'white'}}>
          <CardMedia sx={{textAlign: 'center'}}>
            <PersonAddAlt1Icon sx={{ fontSize: '15vw'}} />
          </CardMedia>
          <CardContent>
            <Typography sx={{ fontSize: '4vw', textAlign: 'center'}}>Create and verify your account</Typography>
          </CardContent>
        </Card>
        <Card variant="outlined" sx={{backgroundColor: '#338395', color: 'white'}}>
          <CardMedia sx={{textAlign: 'center'}}>
            <MailOutlineIcon sx={{ fontSize: '15vw'}} />
          </CardMedia>
          <CardContent>
            <Typography sx={{ fontSize: '4vw', textAlign: 'center' }}>Tell us what services you use</Typography>
          </CardContent>
        </Card>
        <Card variant="outlined" sx={{backgroundColor: '#338395', color: 'white'}}>
          <CardMedia sx={{textAlign: 'center'}}>
            <MobileFriendlyIcon sx={{ fontSize: '15vw'}} />
          </CardMedia>
          <CardContent>
            <Typography sx={{ fontSize: '4vw', textAlign: 'center' }}>Sit back and wait for us to send you your insights</Typography>
          </CardContent>
        </Card>
      </Stack>
    </Container>
  );
};

export default LandingPage;
